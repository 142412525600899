.createcard_input_container{
    background-color: #ffffff;
    border: 1px solid #CED4D9;
    border-radius: 2px;
}

.createcard_input_container:focus-within{
    border: 1px solid #0078ff !important;
    /* box-shadow: 0 0 0 1px #0078ff; */
}

.createcard_input_container.accept:focus-within{
    border: 1px solid #23C16B !important;
    /* box-shadow: 0 0 0 1px #0078ff; */
}

.createcard_input_container.reject:focus-within{
    border: 1px solid var(--red_default) !important;
    /* box-shadow: 0 0 0 1px #0078ff; */
}


/* Rotation input */
.RI----------RI{
    background-color: transparent;
}

/* Intensity input */
.II----------II{
    background-color: transparent;
    width: 100%;
}

/* Select Input */
.SI----------SI{
    background-color: transparent;
}

/* Dimension Input */
.dI----------dI{
    background-color: transparent;
}

/* List Item */
.LI----------LI{
    background-color: transparent;
    /* height: 36px; */
    font-weight: 500;
}

/* Text Input */
.TI----------TI{
    background-color: transparent;
}

/* Visibility Input */
.VI----------VI{
    background-color: transparent;
}

/* Color Picker */
.CP----------CP{
    background-color: transparent;
}

/* Material Select */
.MS----------MS{
    background-color: transparent;
}

/* Composite Select */
.CS----------CS{
    background-color: transparent;
}

/* Checkbox */
.CB----------CB{
    background-color: transparent;
}

/* Text Info */
.TI----------TI{
    background-color: transparent;
}

/* Select and Dimension Input */
.SDI----------SDI{
    background-color: transparent;
}

/* Toggle */
.TG----------TG{
    background-color: transparent;
}

/* Button Array */
.BA----------BA{
    background-color: transparent;
}

/* Top Level Toolbar */
.TT----------TT{
    background-color: transparent;
}

.CP----------CP .createcard_input_container input{
    padding: 0 !important;
}

.SI----------SI .createcard_input_container{
    padding-right: 6px !important;
}

.PS----------PS:hover{
    background-color: #F5F6F8;
}

.PS----------PS:hover .show_on_hover_sheets{
    visibility: visible;
}

.PS----------PS:not(:hover) .show_on_hover_sheets:not(:focus){
    visibility: hidden;
}

.show_on_hover_sheets:focus {
    visibility: visible;
    background: #E6E9EE !important;
}

.options_font {
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #676878;
}

.PS----------PS{
    width: 100%;
    margin: 0;
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 0px 12px;
}

.CT----------CT{
    position: unset;
}

.TT----------TT{
    position: unset;
}

.dropdown_heading_panel{
    font-size: 12px;
    font-weight: 500;
}

.dropdown_panel{
    height: 36px;
    cursor: pointer;
}

.active_top_tab_panel{
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom: 3px solid #3D434D;
    border-right: 1px solid #6c7789;
    background-color: #616B7A;
    cursor: pointer;
}

.active_top_tab_panel_search{
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    /* border-bottom: 3px solid #3D434D;
    border-right: 1px solid #6c7789; */
    background-color: white;
    cursor: pointer;
}

.top_tab_panel{
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom: 3px solid transparent;
    border-right: 1px solid #6c7789;
    cursor: pointer;
}

.top_tab_panel:hover{
    border-bottom: 3px solid #616B7A;
}

.LI----------LI:hover .list_item_blue_hover{
    color: #0078ff !important;
}

.list_item_visible_hover{
    visibility: hidden;
}

.LI----------LI:hover .list_item_visible_hover{
    visibility: visible;
}

.button_panel{
    align-items: center;
    cursor: pointer;
}

.overriden_layer_item {
    background: #FFFAE6;
}

.overriden_layer_item:hover {
    background: #F5F2E6;
}

.normal_layer_item:hover {
    background: #F5F6F8;
}

.disable_feedback:hover {
    background-color: unset !important;
}

.tab_index_panel{
    font-size: 12px;
    padding: 0px 6px;
    border-right: 1px solid #CED4D9;
    display: flex;
    align-items: center;
    color: #a7a8b2;
    min-width: 32px;
    justify-content: center;
}

.disabled_top_tab_panel{
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom: 3px solid transparent;
    border-right: 1px solid #6c7789;
    cursor:not-allowed;
    opacity: 0.5;
}

.top_name_panel{
    height: 40px;
    /* width: 40px; */
    align-items: center;
    /* justify-content: center; */
    display: flex;
    /* border-bottom: 3px solid transparent;
    border-right: 1px solid #6c7789; */
    cursor: pointer;
    padding-left: 8px;
    color: white;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}

.rename_group_input_container{
    width: 100%;
    padding: 8px;
    font-size: 12px;
    height: 40px;
}

.rename_group{
    /* all: unset; */
    height: 100%;
    font-size: 12px;
    border-radius: 2px;
}

.pencil-svg path,
.pencil-svg line,
.pencil-svg polyline,
.pencil-svg rect,
.pencil-svg circle {
  /* stroke: black; */
  fill: black;
}
#panel_search_input{
    /* flex: 1; */
    margin: 0;
    width: calc( 100% - 90px );
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 6px;
    height: 12px;
    background:  #676878;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #eef2f0;
    cursor: pointer;
  }
  

  /* comments */
  .comment-input {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 10px;
      margin-top: 10px;
      padding: 8px;
      border-radius: 4px;
      border: 1px solid #0078ff;
      max-height: 200px;
      overflow: auto;
      background-color: white;
  }

  .comment-input textarea {
      flex: 1;
      min-height: 36px;
      padding: 0px;
      border: none;
      outline: none;
      resize: vertical;
      height: auto;
      overflow: visible;
  }

  .comment-input button {
      padding: 8px 12px;
      margin-left: 8px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
  }

  .comment-input button:hover {
      background-color: #0056b3;
  }

  .suggestion-list{
    min-width: 125px;
    text-align: left;
    font-size: 12px;
    color: black;
    padding: 4px;
    cursor: pointer;
    margin-top: 8px;
    margin-left: 8px;
    border: 1px solid #0078ff;
  }

  .single-suggestion{
    height: 28px;
    display: flex;
    align-items: center;
  }

  .single-suggestion:hover{
    background-color: rgba(24,144,255,.06);
  }

.inf-presets-color-picker {
    display: flex;
    justify-content: center;
}

.inf-presets-color-grid {
    display: grid;
    grid-template-columns: repeat(12, 12px);
    /* Adjust column count as needed */
    grid-gap: 5px;
}

.inf-presets-color-option {
    width: 12px;
    height: 12px;
    cursor: pointer;
    border: 1px solid var(--light-border-color);
    border-radius: 1px;
}

.inf-presets-color-option.selected {
    border: 1px solid #0078ff;

}

.image_map_select_dropdown {
    width: calc( var(--panel_width) - 16px );
    max-height: 240px;
    overflow-y: auto;
    position: absolute !important;
    margin-top: 42px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.5);
    /* left:0 !important; 
    top:40px !important; */
}

.sheet-dropdown-divider {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    margin: 6px 10px;
  }

  /* .panel_numeric_integer_input:focus {
    color: black;
  } */
  
  .panel_numeric_integer_input:not(:focus-within)::after {
    content: attr(data-decimal);
    color: gray;
    display: inline-block;
    /* background-color: #0078ff; */
    font-size: 12px;
    opacity: 1;
  }
  
  .createcard_input_container::-webkit-scrollbar{
    height: 0px;
  }

  .panel_button_array > :not(:last-child){
    margin-right: 8px;
  }

.panel_tree_parent_header{
    padding: 8px 12px;
}

.panel_tree_leaf{
    padding: 8px 12px;
}

.panel_tree_leaf_indicator{
    /* margin-left: 4px; */
    transform: translateX(5px);
    /* display: inline-block; */
    position: relative;
}
.panel_tree_leaf_indicator::before{
    /* border: 1px solid; */
    /* height: 20px; */
    content: '';
    top: 0px;
    height: 36px;
    position: absolute;
    border-inline-end: 1px solid var(--ant-color-border, #000);
}
.panel_tree_leaf_indicator::after{
    content: '';
    content: '';
    top: 0px;
    height: 18px;
    width: 4px;
    position: absolute;
    border-bottom: 1px solid var(--ant-color-border, #000);
}

.panel_tree_parent_collapse > .panel_tree_item_container.last_tree_item > .panel_tree_leaf_indicator_container > .panel_tree_leaf_indicator::before{
    height: 18px !important;
}

.panel_tree_parent_collapse > .panel_tree_collapse_indicator_container > .panel_tree_collapse_indicator::before{
    height: 100%;
    content: '';
    position: absolute;
    border-right: 1px solid;
    transform: translateX(-7px);
}

.panel_tree_item{
    padding: 4px 8px;
    border-radius: 4px;
    flex: 1;
    min-width: 40px;
    text-overflow: ellipsis;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.panel_tree_item:hover{
    background-color: rgba(54, 134, 247, 0.1);
    color: #0078ff;
}

.root_tree_item{
    padding: 4px 8px 4px 0px;
    border-radius: 4px;
    flex: 1;
    min-width: 40px;
    text-overflow: ellipsis;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.BI----------BI.highlight_and_fade_bi{
    animation: blink-box-shadow 1.5s 2 ease-in-out;
}

@keyframes blink-box-shadow {
    25% {box-shadow: inset 0px 0px 0px 2px #0078FF80;}
    75% {box-shadow: inset 0px 0px 0px 2px #0078FF80;}
}