.inf-flex {
    display: flex;
}

.inf-flex-col {
    flex-direction: column;
}

.inf-ml-auto {
    margin-left: auto;
}

.inf-justify-end {
    justify-content: end;
}

.inf-justify-center {
    justify-content: center;
}

.inf-justify-between {
    justify-content: space-between;
}

.inf-items-center {
    align-items: center;
}

.inf-width-full {
    width: 100%;
}

.inf-height-full {
    height: 100%;
}

/* Flex container for grid view */
.inf-flex-grid {
    display: flex;
    flex-wrap: wrap;
    gap: var(--inf-flex-grid-gap);
}

/* Utility classes for number of cards and spacing */
.inf-wrap-2 {
    --flex-basis: calc(50% - var(--inf-flex-grid-gap));
}

.inf-wrap-3 {
    --flex-basis: calc(33.3333% - var(--inf-flex-grid-gap));
}

.inf-wrap-4 {
    --flex-basis: calc(25% - var(--inf-flex-grid-gap));
}

.inf-wrap-5 {
    --flex-basis: calc(20% - var(--inf-flex-grid-gap));
}

/* Responsive utility classes */
@media screen and (min-width: 640px) {
    .inf-wrap-2-sm {
        --flex-basis: calc(50% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-3-sm {
        --flex-basis: calc(33.3333% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-4-sm {
        --flex-basis: calc(25% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-5-sm {
        --flex-basis: calc(20% - var(--inf-flex-grid-gap));
    }
}

@media screen and (min-width: 768px) {
    .inf-wrap-2-md {
        --flex-basis: calc(50% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-3-md {
        --flex-basis: calc(33.3333% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-4-md {
        --flex-basis: calc(25% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-5-md {
        --flex-basis: calc(20% - var(--inf-flex-grid-gap));
    }
}

@media screen and (min-width: 1024px) {
    .inf-wrap-2-lg {
        --flex-basis: calc(50% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-3-lg {
        --flex-basis: calc(33.3333% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-4-lg {
        --flex-basis: calc(25% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-5-lg {
        --flex-basis: calc(20% - var(--inf-flex-grid-gap));
    }
}

@media screen and (min-width: 1280px) {
    .inf-wrap-2-xl {
        --flex-basis: calc(50% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-3-xl {
        --flex-basis: calc(33.3333% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-4-xl {
        --flex-basis: calc(25% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-5-xl {
        --flex-basis: calc(20% - var(--inf-flex-grid-gap));
    }
}

@media screen and (min-width: 1536px) {
    .inf-wrap-2-2xl {
        --flex-basis: calc(50% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-3-2xl {
        --flex-basis: calc(33.3333% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-4-2xl {
        --flex-basis: calc(25% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-5-2xl {
        --flex-basis: calc(20% - var(--inf-flex-grid-gap));
    }
}

/* Media queries for responsiveness */
@media screen and (max-width: 600px) {
    .inf-flex-grid {
        gap: 0;
        /* No gap between items on small screens */
    }

    .inf-wrap-2,
    .inf-wrap-3,
    .inf-wrap-4,
    .inf-wrap-5,
    .inf-wrap-2-md,
    .inf-wrap-3-md,
    .inf-wrap-4-md,
    .inf-wrap-5-md,
    .inf-wrap-2-lg,
    .inf-wrap-3-lg,
    .inf-wrap-4-lg,
    .inf-wrap-5-lg
    .inf-wrap-2-xl,
    .inf-wrap-3-xl,
    .inf-wrap-4-xl,
    .inf-wrap-5-xl,
    .inf-wrap-2-2xl,
    .inf-wrap-3-2xl,
    .inf-wrap-4-2xl,
    .inf-wrap-5-2xl  {
        --flex-basis: calc(100% - var(--inf-flex-grid-gap));
    }
}

.inf-gap-0 {
    --inf-flex-grid-gap: 0rem;
}

.inf-gap-1 {
    --inf-flex-grid-gap: 0.25rem;
}

.inf-gap-2 {
    --inf-flex-grid-gap: 0.5rem;
}

.inf-gap-3 {
    --inf-flex-grid-gap: 0.75rem;
}

.inf-gap-4 {
    --inf-flex-grid-gap: 1rem;
}

.inf-gap-5 {
    --inf-flex-grid-gap: 1.25rem;
}

.inf-gap-6 {
    --inf-flex-grid-gap: 1.5rem;
}

.inf-gap-7 {
    --inf-flex-grid-gap: 1.75rem;
}

.inf-gap-8 {
    --inf-flex-grid-gap: 2rem;
}

.inf-gap-9 {
    --inf-flex-grid-gap: 2.25rem;
}

.inf-gap-10 {
    --inf-flex-grid-gap: 2.5rem;
}

.inf-gap-11 {
    --inf-flex-grid-gap: 2.75rem;
}

.inf-gap-12 {
    --inf-flex-grid-gap: 3rem;
}

/* General styling for grid items */
.inf-flex-grid-item {
    flex-basis: var(--flex-basis);
    min-width: 200px;
}

/* Info classes for text alignment */
.inf-text-left {
    text-align: left;
}

.inf-text-center {
    text-align: center;
}

.inf-text-right {
    text-align: right;
}

.inf-text-justify {
    text-align: justify;
}

.inf-text-start {
    text-align: start;
}

.inf-text-end {
    text-align: end;
}

.inf-border{
    border: 1px solid var(--light-border-color);
}