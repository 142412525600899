.infurnia_modal_header{
    height:48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E6E9EE;
    background-color:#FFFFFF;
    color: #202126;
    font-weight: 700;
    box-shadow: 0px 1px 6px 0px #0000000D;
    border-radius: 4px 4px 0px 0px;
    font-family: 'DM Sans', sans-serif;
    padding: 0px 16px;
    font-size: 16px;
  }
  .infurnia_modal_footer{
    height:52px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color:#FFFFFF;
    padding: 0px 16px;
    border-top: 1px solid #E6E9EE;
    box-shadow: 0px -1px 6px 0px #0000000D;
    border-radius: 0px 0px 4px 4px;
    font-family: 'DM Sans', sans-serif;
  }
  .infurnia_modal_body{
    /* height: 460px; */
    /* overflow: auto; */
    padding: 16px;
    background-color:#F5F6F8;
    min-height: 100px;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
  }

  .infurnia_modal_footer > :not(:last-child) {
    margin-right: 12px;
  }
  