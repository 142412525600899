.inf_nav_tabs_blue_background {
    margin: 4px 0px;
    padding: 0px 4px;
    font-size: 14px;
    color: #676878;
    cursor: pointer;
    border-bottom: 2px solid #E4E7EC;
}

.inf_nav_tabs_blue_background.active {
    margin: 4px 0px;
    padding: 0px 4px;
    font-size: 14px;
    color: #676878;
    cursor: pointer;
    background: #E8EFFD;
}
